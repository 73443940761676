import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  id: "Isolation_Mode",
  viewBox: "0 0 1080 1080"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".cls-1{fill:#fb0032}")
        ])),
        _: 1
      }))
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("path", {
      d: "m540.13 35.03 507.5 377.2v641.06h-1015V412.23zm0-32.03L6.92 399.31V1079h1066.41V399.31z",
      class: "cls-1"
    }, null, -1)),
    _cache[2] || (_cache[2] = _createElementVNode("path", {
      d: "M711.35 859.67V712.95l-48.37 103.84a42.67 42.67 0 0 1-38.68 24.65H455.95a42.67 42.67 0 0 1-38.68-24.65L368.9 712.95v146.72H265.47V484.53H374.1l120.08 253.49h91.88l120.08-253.49h108.63v375.14H711.34Z",
      class: "cls-1"
    }, null, -1))
  ]))
}
export default { render: render }